import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Tabs, Tooltip } from "antd";
import { OrderedListOutlined, PieChartFilled } from "@ant-design/icons";

const CropDistributionChart = ({ cropCultures }) => {
    const [option, setOption] = useState("chart");

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const series = cropCultures.map((culture) =>
        parseFloat(culture.percentage.toFixed(1))
    );
    const labels = cropCultures.map((culture) =>
        capitalizeFirstLetter(culture.name)
    );
    const areas = cropCultures.map((culture) =>
        parseFloat(culture.area.toFixed(1)).toLocaleString("pt-BR", {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
        })
    );
    const colors = ["#1890FF", "#FA8C16", "#52C41A", "#EB2F96"];

    const options1 = {
        chart: {
            type: "pie",
            background: "#141414",
        },
        labels: labels,
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: false,
        },
        theme: {
            mode: "dark",
        },
        colors: colors,
    };

    const chartOptions = [
        { key: "chart", icon: <PieChartFilled /> },
        { key: "table", icon: <OrderedListOutlined /> },
    ];

    return (
        <div className="sense-plus-sidebar-chart-container">
            <div className="sense-plus-sidebar-chart-header">
                Distribuição de culturas
                <Tabs
                    className="sense-plus-chart-tab"
                    defaultActiveKey="chart"
                    items={chartOptions}
                    onChange={(key) => setOption(key)}
                />
            </div>
            {option === "chart" ? (
                <div
                    style={{
                        marginBottom: "16px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <ReactApexChart
                        options={options1}
                        series={series}
                        height={136}
                        width={136}
                        type="pie"
                    />
                </div>
            ) : (
                <div className="sense-plus-sidebar-table">
                    {labels.map((label, index) => (
                        <Tooltip
                            key={index}
                            title={`Área: ${cropCultures[index].area} ha`}
                            color="#9f45dd"
                            placement="topRight"
                        >
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    padding: "4px 8px 4px 8px",
                                    backgroundColor: "#141414",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "8px",
                                        flex: 1,
                                    }}
                                >
                                    <span
                                        style={{
                                            width: "6px",
                                            height: "6px",
                                            backgroundColor: colors[index],
                                            display: "inline-block",
                                            borderRadius: "50%",
                                        }}
                                    ></span>
                                    {label}
                                </div>
                                <div
                                    style={{
                                        textAlign: "center",
                                        flex: 1,
                                    }}
                                >
                                    {series[index]}%
                                </div>
                                <div
                                    style={{
                                        textAlign: "right",
                                        flex: 1,
                                    }}
                                >
                                    {areas[index]} ha
                                </div>
                            </div>
                        </Tooltip>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CropDistributionChart;
