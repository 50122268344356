import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Button, Input, Space, Tooltip } from "antd";
import {
    FilterOutlined,
    SearchOutlined,
    CopyOutlined,
} from "@ant-design/icons";

const TicketTable = ({ data, mapRef }) => {
    const navigate = useNavigate();
    const searchInput = useRef(null);

    const prettyCrops = {
        soja: "Soja",
        milho: "Milho",
        arroz: "Arroz",
        trigo: "Trigo",
        feijao1: "Feijão",
        outros: "Outros",
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Buscar`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => confirm()}
                    style={{ marginBottom: 8, display: "block" }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => confirm()}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 120, backgroundColor: "#9f45dd" }}
                    >
                        Buscar
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => close()}
                        style={{ color: "#9f45dd", border: "none" }}
                    >
                        Fechar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? "#9f45dd" : "#ccc" }} />
        ),
        onFilter: (value, record) => {
            return record[dataIndex]
                ?.toString()
                .toLowerCase()
                .includes(value.toLowerCase());
        },
    });

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: 40,
            ellipsis: true,
        },
        {
            title: "ID do cliente",
            dataIndex: "clientId",
            key: "clientId",
            width: 72,
            ellipsis: true,
            ...getColumnSearchProps("clientId"),
            render: (value) => (
                <Space
                    style={{ width: "100%", justifyContent: "space-between" }}
                >
                    <div
                        style={{
                            flex: 1,
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            maxWidth: "100px",
                        }}
                    >
                        {value}
                    </div>
                    <Tooltip title="Copiar">
                        <Button
                            type="link"
                            icon={<CopyOutlined />}
                            onClick={() => copyToClipboard(value)}
                        />
                    </Tooltip>
                </Space>
            ),
        },
        {
            title: "Centróide",
            dataIndex: "lat",
            key: "centroid",
            width: 100,
            ellipsis: true,
            render: (_, record) => {
                const centroidText = `${record.lat.toFixed(
                    6
                )}, ${record.long.toFixed(6)}`;
                return (
                    <Space
                        style={{
                            width: "100%",
                            justifyContent: "space-between",
                        }}
                    >
                        <span
                            style={{
                                flex: 1,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}
                        >
                            {centroidText}
                        </span>
                        <Tooltip title="Copiar">
                            <Button
                                type="link"
                                icon={<CopyOutlined />}
                                onClick={() => copyToClipboard(centroidText)}
                            />
                        </Tooltip>
                    </Space>
                );
            },
        },
        {
            title: "Cultura",
            dataIndex: "cropType",
            key: "cropType",
            width: 44,
            ellipsis: true,
            render: (value) => prettyCrops[value] || value,
            filters: [
                { value: "soja", text: "Soja" },
                { value: "milho", text: "Milho" },
                { value: "arroz", text: "Arroz" },
                { value: "trigo", text: "Trigo" },
                { value: "outros", text: "Outros" },
            ],
            onFilter: (value, record) => record.cropType === value,
            filterIcon: (filtered) => (
                <FilterOutlined
                    style={{ color: filtered ? "#9f45dd" : "#ccc" }}
                />
            ),
        },
        {
            title: "UF",
            dataIndex: "state",
            key: "state",
            width: 32,
            ellipsis: true,
        },
        {
            title: "Município",
            dataIndex: "municipality",
            key: "municipality",
            width: 72,
            ellipsis: true,
        },
        {
            title: "Área",
            dataIndex: "area",
            key: "area",
            width: 48,
            ellipsis: true,
            render: (value) => `${Math.round(value)} ha`,
        },
        {
            title: "Opções",
            key: "actions",
            width: 108,
            render: (_, record) => (
                <div style={{ minWidth: 240 }}>
                    <Button
                        type="link"
                        style={{ color: "#1890ff" }}
                        onClick={() => {
                            if (mapRef.current) {
                                mapRef.current.flyTo({
                                    center: [record.long, record.lat],
                                    zoom: 14,
                                });
                            }
                        }}
                    >
                        Ver no mapa
                    </Button>
                    <Button
                        type="link"
                        style={{ color: "#1890ff" }}
                        onClick={() => navigate(`/sense/${record.id}`)}
                    >
                        Abrir Ticket
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <div style={{ position: "relative" }}>
            <Table
                className="ticket-table"
                columns={columns}
                dataSource={data}
                pagination={false}
                bordered={false}
                size="small"
                scroll={{ y: 240 }}
                virtual
                rowHoverable={false}
            />
        </div>
    );
};

export default TicketTable;
