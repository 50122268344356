import React from "react";
import TicketListing from "../components/TicketListing";
import { fetchAuraTickets } from "../components/api/api";

function Aura() {
    return (
        <div>
            <TicketListing fetchTickets={fetchAuraTickets} />
        </div>
    );
}

export default Aura;
