const appToEsgId = {
    "APP": 1,
    "APP_1": 1,
    "APP_2": 1,
    "APP_3": 1,
    "APP_4": 1,
    "APP_5": 1,
    "APP_6": 1,
    "APP_7": 1,
    "APP_8": 1,
    "APP_9": 1,
    "APP_AREA_VN": 1,
    "APP_VAZIO": 1,
    "APP_TOTAL": 1,

    "AREA_ALTITUDE_SUPERIOR_1800": 2,
    "APP_AREA_ALTITUDE_SUPERIOR_1800": 2,

    "AREA_CONSOLIDADA": 3,
    "APP_AREA_CONSOLIDADA": 3,

    "AREA_DECLIVIDADE_MAIOR_45": 4,
    "APP_AREA_DECLIVIDADE_MAIOR_45": 4,

    "AREA_IMOVEL": 5,
    "AREA_IMOVEL_1": 5,
    "APP_AREA_IMOVEL": 5,
    "AREA_IMOVEL_SNCI": 5,

    "AREA_POUSIO": 6,
    "APP_AREA_POUSIO": 6,

    "AREA_TOPO_MORRO": 7,
    "APP_AREA_TOPO_MORRO": 7,

    "BANHADO": 8,
    "APP_BANHADO": 8,

    "BORDA_CHAPADA": 9,
    "APP_BORDA_CHAPADA": 9,

    "HIDROGRAFIA": 10,
    "HIDROGRAFIA_1": 10,
    "APP_HIDROGRAFIA": 10,

    "MANGUEZAL": 11,
    "MANGUEZAL_1": 11,
    "APP_MANGUEZAL": 11,

    "NASCENTE_OLHO_DAGUA": 12,
    "APP_NASCENTE_OLHO_DAGUA": 12,

    "RESERVA_LEGAL": 13,
    "RESERVA_LEGAL_1": 13,
    "ARL_AVERBADA": 13,
    "ARL_PROPOSTA": 13,

    "RESTINGA": 14,
    "APP_RESTINGA": 14,

    "SERVIDAO_ADMINISTRATIVA": 15,
    "APP_SERVIDAO_ADMINISTRATIVA": 15,

    "USO_RESTRITO": 16,
    "USO_RESTRITO_1": 16,
    "APP_USO_RESTRITO": 16,

    "VEGETACAO_NATIVA": 17,
    "VEGETACAO_NATIVA_1": 17,
    "APP_VEGETACAO_NATIVA": 17,

    "VEREDA": 18,
    "APP_VEREDA": 18,

    "AMAZON_DEFORESTATION": 19,
    "APP_AMAZON_DEFORESTATION": 19,
    "DESMATAMENTO_PRODES": 19,

    "ICMBIO": 20,
    "APP_ICMBIO": 20,

    "IBAMA": 21,
    "APP_IBAMA": 21,

    "ASSENTAMENTO": 22,
    "APP_ASSENTAMENTO": 22,

    "TERRAS_INDIGENAS": 23,
    "APP_TERRAS_INDIGENAS": 23,
};
          
export { appToEsgId };
