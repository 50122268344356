import { React } from "react";
import "./sensePlusMap";
import atypicalEventsContent from "../dashboard/AtypicalEventsContent";
import { ExclamationCircleFilled, FlagFilled } from "@ant-design/icons";

const SensePlusPopover = ({ ticket }) => {
    return (
        <div className="sensePlusMapPopoverInfo">
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "4px",
                    overflow: "hidden",
                }}
            >
                <div className="sensePlusProposalIdTitle">ID do cliente:</div>
                <div className="sensePlusProposalId">{ticket.clientId}</div>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    overflow: "hidden",
                }}
            >
                <div
                    className="sensePlusProposalIdTitle"
                    style={{ minWidth: "100px" }}
                >
                    ID da proposta:
                </div>
                <div className="sensePlusProposalId">{ticket.proposalId}</div>
            </div>
            <div className="sensePlusPopupArea">
                Área: {Math.floor(ticket.area)} Hectares
            </div>
            <div>
                {ticket.notification.notification_type && (
                    <div className="sensePopupInfoText">
                        <ExclamationCircleFilled style={{ color: "#FFA940" }} />
                        {
                            atypicalEventsContent[
                                ticket.notification.notification_type
                            ].title
                        }
                    </div>
                )}
                {ticket.sicarOverlap && (
                    <div className="sensePopupInfoText">
                        <FlagFilled style={{ color: "#F5222D" }} />
                        Detectado intersecção de ESG
                    </div>
                )}
            </div>
        </div>
    );
};

export default SensePlusPopover;
