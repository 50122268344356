import React, { useState, useEffect } from "react";
import NewAuraReport1 from "./AuraPageByResolution/AuraNormalRes"; // Importa a primeira versão da página
import NewAuraReport2 from "./AuraPageByResolution/AuraSmallRes"; // Importa a segunda versão da página
import NewAuraReport3 from "./AuraPageByResolution/AuraSmallestRes"; //Importa a terceira versão da página
import NewAuraReport4 from "./AuraPageByResolution/AuraMidRes"; //Importa a quarta versão da página
const NewAuraReport = () => {
    const [screenWidth, setScreenWidth] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        // Adiciona o evento para monitorar redimensionamento da janela
        window.addEventListener("resize", handleResize);
        handleResize(); // Chamada inicial para verificar o tamanho da tela
        // Remove o evento ao desmontar o componente
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    if (screenWidth < 1201) {
        return <NewAuraReport3 />;
    } else if (screenWidth > 1200 && screenWidth <= 1600) {
        return <NewAuraReport2 />;
    } else if (screenWidth > 1600 && screenWidth <= 1703) {
        return <NewAuraReport4 />;
    } else {
        return <NewAuraReport1 />;
    }
};

export default NewAuraReport;
