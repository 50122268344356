import React from "react";
import { DownloadOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import TicketTable from "./SensePlusTable";
import * as Papa from "papaparse";
import atypicalEventsContent from "../dashboard/AtypicalEventsContent";

import "./sensePlusBottomBar.css";
import { Button, Tooltip } from "antd";

const BottomBar = ({
    bottomBarOpen,
    toggleBottomBar,
    sidebarOpen,
    tickets,
    mapRef,
    hasMore,
}) => {
    const exportToCSV = () => {
        const formatDate = (date) => {
            if (!date) return null;
            const formatter = new Intl.DateTimeFormat("pt-BR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
            });
            return formatter.format(new Date(date));
        };

        const filteredData = tickets.map(({ notification, ...rest }) => {
            const formattedNotification =
                notification &&
                notification.notification_type &&
                notification.notification_date
                    ? atypicalEventsContent[notification.notification_type]
                          ?.title
                        ? `Tipo: ${
                              atypicalEventsContent[
                                  notification.notification_type
                              ].title
                          }, Data: ${formatDate(
                              notification.notification_date
                          )}`
                        : "Tipo de alerta desconhecido"
                    : "Sem alertas";

            return {
                ...rest,
                notification: formattedNotification,
            };
        });

        const csv = Papa.unparse(filteredData);
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "tickets.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div
            className="sense-plus-bottom-bar"
            style={{
                height: bottomBarOpen ? "378px" : "56px",
                left: sidebarOpen ? "424px" : "48px",
                width: sidebarOpen ? "calc(100% - 424px)" : "calc(100% - 48px)",
            }}
        >
            <div className="sense-plus-bottom-bar-header">
                <div className="sense-plus-bottom-bar-buttons">
                    <Tooltip
                        title={
                            hasMore
                                ? "Aguarde enquanto buscamos os dados do nosso servidor"
                                : ""
                        }
                    >
                        <Button
                            type="primary"
                            icon={<DownloadOutlined />}
                            onClick={exportToCSV}
                            className="ticket-Table-CSV"
                            disabled={hasMore}
                        >
                            Baixar tabela
                        </Button>
                    </Tooltip>
                    <Button
                        className="toggle-sense-plus-bottom-bar"
                        onClick={toggleBottomBar}
                        icon={
                            bottomBarOpen ? (
                                <DownOutlined style={{ marginRight: "8px" }} />
                            ) : (
                                <UpOutlined style={{ marginRight: "8px" }} />
                            )
                        }
                    >
                        {bottomBarOpen ? "Ocultar tabela" : "Abrir tabela"}
                    </Button>
                </div>
            </div>

            {bottomBarOpen && (
                <div
                    className="sense-plus-bottom-bar-body"
                    style={{
                        padding: "16px",
                        color: "white",
                        height: "calc(100% - 56px)",
                    }}
                >
                    <TicketTable data={tickets} mapRef={mapRef} />
                </div>
            )}
        </div>
    );
};

export default BottomBar;
