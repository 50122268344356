import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom/client";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useNavigate } from "react-router-dom";
import "./SensePlusMap.css";
import SensePlusPopover from "./SensePlusPopover";
import { Button } from "antd";
import { MonitorOutlined } from "@ant-design/icons";
import SensePlusMapLegend from "./SensePlusMapLegend";

function SensePlusMap({
    tickets,
    tableTickets,
    mapOption,
    mapboxToken,
    sidebarOpen,
    toggleSidebar,
    setFilterString,
    setStateFilter,
    mapRef,
}) {
    const navigate = useNavigate();
    const mapContainerRef = useRef(null);
    const [isMapLoaded, setIsMapLoaded] = useState(false);
    const popupRef = useRef(null);
    const [popupInfo, setPopupInfo] = useState(null);

    useEffect(() => {
        if (!mapboxToken) return;

        mapboxgl.accessToken = mapboxToken;
        mapRef.current = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: "mapbox://styles/felipegalo/cluhf94ix00zu01qlhwrx8oyq",
            center: [-52, -27],
            zoom: 6,
        });

        mapRef.current.on("load", () => {
            mapRef.current.addSource("monitoring-tickets", {
                type: "geojson",
                data: { type: "FeatureCollection", features: [] },
                cluster: false,
            });

            mapRef.current.addSource("risk-zone-tickets", {
                type: "geojson",
                data: { type: "FeatureCollection", features: [] },
                cluster: true,
                clusterMaxZoom: 14,
                clusterRadius: 50,
            });

            addMonitoringTicketLayer();
            addRiskZoneTicketLayer();

            setIsMapLoaded(true);
        });

        return () => {
            if (mapRef.current) mapRef.current.remove();
        };
    }, [mapboxToken]);

    function updateTicketLayers() {
        if (!mapRef.current) return;

        const monitoringData = {
            type: "FeatureCollection",
            features: tickets.map((ticket) => ({
                type: "Feature",
                properties: { color: ticket.monitoringColor },
                id: ticket.id || `monitoring-${index}`,
                geometry: {
                    type: "Point",
                    coordinates: [ticket.long, ticket.lat],
                },
            })),
        };

        const riskZoneData = {
            type: "FeatureCollection",
            features: tickets.map((ticket) => ({
                type: "Feature",
                properties: { color: ticket.riskColor },
                id: ticket.id || `risk-${index}`,
                geometry: {
                    type: "Point",
                    coordinates: [ticket.long, ticket.lat],
                },
            })),
        };

        const monitoringSource = mapRef.current.getSource("monitoring-tickets");
        const riskZoneSource = mapRef.current.getSource("risk-zone-tickets");

        if (monitoringSource) monitoringSource.setData(monitoringData);
        if (riskZoneSource) riskZoneSource.setData(riskZoneData);
    }

    function addMonitoringTicketLayer() {
        if (!mapRef.current) return;

        mapRef.current.addLayer({
            id: "monitoring-points",
            type: "circle",
            source: "monitoring-tickets",
            paint: {
                "circle-color": ["get", "color"],
                "circle-radius": 6,
                "circle-stroke-width": 1,
                "circle-stroke-color": "#fff",
            },
        });
    }

    function addRiskZoneTicketLayer() {
        if (!mapRef.current) return;

        mapRef.current.addLayer({
            id: "risk-zone-points",
            type: "circle",
            source: "risk-zone-tickets",
            filter: ["!", ["has", "point_count"]],
            layout: {
                visibility: "none",
            },
            paint: {
                "circle-color": ["get", "color"],
                "circle-radius": 6,
                "circle-stroke-width": 1,
                "circle-stroke-color": "#fff",
            },
        });

        mapRef.current.addLayer({
            id: "risk-zone-clusters",
            type: "circle",
            source: "risk-zone-tickets",
            filter: ["has", "point_count"],
            layout: {
                visibility: "none",
            },
            paint: {
                "circle-color": ["get", "dynamicColor"],
                "circle-radius": [
                    "step",
                    ["get", "point_count"],
                    12,
                    16,
                    24,
                    28,
                    32,
                ],
                "circle-stroke-width": 1,
                "circle-stroke-color": "#fff",
            },
        });

        mapRef.current.addLayer({
            id: "risk-zone-cluster-count",
            type: "symbol",
            source: "risk-zone-tickets",
            filter: ["has", "point_count"],
            layout: {
                visibility: "none",
            },
            layout: {
                "text-field": "{point_count_abbreviated}",
                "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                "text-size": 12,
            },
            paint: {
                "text-color": "#ffffff",
            },
        });
    }

    useEffect(() => {
        function toggleLayers() {
            if (!mapRef.current || !mapRef.current.isStyleLoaded()) return;

            const map = mapRef.current;

            if (map.getLayer("monitoring-points")) {
                map.setLayoutProperty(
                    "monitoring-points",
                    "visibility",
                    mapOption === "monitoring" ? "visible" : "none"
                );
            }

            if (map.getLayer("risk-zone-points")) {
                map.setLayoutProperty(
                    "risk-zone-points",
                    "visibility",
                    mapOption === "riskZone" ? "visible" : "none"
                );
            }

            if (map.getLayer("risk-zone-clusters")) {
                map.setLayoutProperty(
                    "risk-zone-clusters",
                    "visibility",
                    mapOption === "riskZone" ? "visible" : "none"
                );
            }

            if (map.getLayer("risk-zone-cluster-count")) {
                map.setLayoutProperty(
                    "risk-zone-cluster-count",
                    "visibility",
                    mapOption === "riskZone" ? "visible" : "none"
                );
            }
        }

        toggleLayers();
    }, [mapOption, tickets, isMapLoaded]);

    useEffect(() => {
        if (isMapLoaded) updateTicketLayers();
        if (tickets.length == 0 && popupRef.current) {
            popupRef.current.remove();
        }
    }, [tickets, isMapLoaded]);

    // setup interactivity
    useEffect(() => {
        if (!isMapLoaded || !mapRef.current) return;

        function handleClusterClick(e) {
            const features = mapRef.current.queryRenderedFeatures(e.point, {
                layers: ["risk-zone-clusters"],
            });

            if (!features.length) return;

            const clusterId = features[0].properties.cluster_id;

            mapRef.current
                .getSource("risk-zone-tickets")
                .getClusterExpansionZoom(clusterId, (err, zoom) => {
                    if (err) return;

                    mapRef.current.easeTo({
                        center: features[0].geometry.coordinates,
                        zoom: zoom,
                    });
                });
        }

        function handleMouseEnter() {
            mapRef.current.getCanvas().style.cursor = "pointer";
        }

        function handleMouseLeave() {
            mapRef.current.getCanvas().style.cursor = "";
        }

        mapRef.current.on("click", "risk-zone-clusters", handleClusterClick);
        mapRef.current.on("mouseenter", "risk-zone-clusters", handleMouseEnter);
        mapRef.current.on("mouseleave", "risk-zone-clusters", handleMouseLeave);

        return () => {
            if (!mapRef.current) return;
            mapRef.current.off(
                "click",
                "risk-zone-clusters",
                handleClusterClick
            );
            mapRef.current.off(
                "mouseenter",
                "risk-zone-clusters",
                handleMouseEnter
            );
            mapRef.current.off(
                "mouseleave",
                "risk-zone-clusters",
                handleMouseLeave
            );
        };
    }, [isMapLoaded]);

    useEffect(() => {
        if (!isMapLoaded || !mapRef.current) return;

        function handlePointClick(e) {
            const features = [
                ...mapRef.current.queryRenderedFeatures(e.point, {
                    layers: ["risk-zone-points"],
                }),
                ...mapRef.current.queryRenderedFeatures(e.point, {
                    layers: ["monitoring-points"],
                }),
            ];

            const validFeature = features.find((feature) => feature?.id);

            if (!validFeature) return;

            const ticketId = features[0].id;
            const ticketData = tableTickets.find(
                (ticket) => ticket.id === ticketId
            );

            if (!ticketData) return;

            setPopupInfo({
                id: ticketData.id,
                clientId: ticketData.clientId || "N/A",
                proposalId: ticketData.proposalId || "N/A",
                area: ticketData.area || 0,
                sicarOverlap: ticketData.sicarOverlap,
                notification: ticketData.notification,
                municipality: ticketData.municipality || "Desconhecido",
                long: ticketData.long,
                lat: ticketData.lat,
            });
        }

        function handleMouseEnter() {
            mapRef.current.getCanvas().style.cursor = "pointer";
        }

        function handleMouseLeave() {
            mapRef.current.getCanvas().style.cursor = "";
        }

        mapRef.current.on("click", "monitoring-points", handlePointClick);
        mapRef.current.on("click", "risk-zone-points", handlePointClick);
        mapRef.current.on("mouseenter", "monitoring-points", handleMouseEnter);
        mapRef.current.on("mouseenter", "risk-zone-points", handleMouseEnter);
        mapRef.current.on("mouseleave", "monitoring-points", handleMouseLeave);
        mapRef.current.on("mouseleave", "risk-zone-points", handleMouseLeave);

        return () => {
            if (!mapRef.current) return;
            mapRef.current.off("click", "monitoring-points", handlePointClick);
            mapRef.current.off("click", "risk-zone-points", handlePointClick);
            mapRef.current.off(
                "mouseenter",
                "monitoring-points",
                handleMouseEnter
            );
            mapRef.current.off(
                "mouseenter",
                "risk-zone-points",
                handleMouseEnter
            );
            mapRef.current.off(
                "mouseleave",
                "monitoring-points",
                handleMouseLeave
            );
            mapRef.current.off(
                "mouseleave",
                "risk-zone-points",
                handleMouseLeave
            );
        };
    }, [isMapLoaded, tableTickets]);

    const clusterColorsRef = useRef({});
    useEffect(() => {
        if (!isMapLoaded || !mapRef.current || mapOption !== "riskZone") return;

        function updateClusterColor(clusterId) {
            if (
                !mapRef.current ||
                !clusterId ||
                clusterColorsRef.current[clusterId]
            )
                return;

            mapRef.current
                .getSource("risk-zone-tickets")
                .getClusterLeaves(clusterId, Infinity, 0, (err, leaves) => {
                    if (err) return;

                    const colorCount = {};

                    leaves.forEach((point) => {
                        const pointColor = point.properties?.color;
                        if (pointColor) {
                            colorCount[pointColor] =
                                (colorCount[pointColor] || 0) + 1;
                        }
                    });

                    if (Object.keys(colorCount).length === 0) return;

                    const predominantColor = Object.keys(colorCount).reduce(
                        (a, b) => (colorCount[a] > colorCount[b] ? a : b)
                    );

                    clusterColorsRef.current[clusterId] = predominantColor;

                    updateClusterColorExpression();
                });
        }

        function updateClusterColorExpression() {
            if (!mapRef.current.getLayer("risk-zone-clusters")) return;

            const colorExpression = ["case"];

            Object.entries(clusterColorsRef.current).forEach(([id, color]) => {
                if (color) {
                    colorExpression.push(
                        ["==", ["get", "cluster_id"], parseInt(id, 10)],
                        color
                    );
                }
            });

            colorExpression.push("#fff"); // Cor padrão

            mapRef.current.setPaintProperty(
                "risk-zone-clusters",
                "circle-color",
                colorExpression
            );
        }

        function updateClusters() {
            if (!mapRef.current) return;

            const clusters = mapRef.current.querySourceFeatures(
                "risk-zone-tickets",
                {
                    filter: ["has", "point_count"],
                }
            );

            clusters.forEach((cluster) => {
                const clusterId = cluster.properties?.cluster_id;
                updateClusterColor(clusterId);
            });
        }

        mapRef.current.on("data", (e) => {
            if (e.sourceId === "risk-zone-tickets" && e.isSourceLoaded) {
                updateClusters();
            }
        });

        return () => {
            if (mapRef.current) {
                mapRef.current.off("data", updateClusters);
            }
        };
    }, [isMapLoaded, mapOption]);

    useEffect(() => {
        if (popupInfo && mapRef.current) {
            if (popupRef.current) {
                popupRef.current.remove();
            }

            const container = document.createElement("div");

            ReactDOM.createRoot(container).render(
                <div className="sensePlusPopupContainer">
                    <SensePlusPopover ticket={popupInfo} />
                    <Button
                        className="sensePlusPopupButton"
                        icon={<MonitorOutlined />}
                        onClick={() => navigate(`/sense/${popupInfo.id}`)}
                    >
                        Ver no monitoramento
                    </Button>
                </div>
            );

            popupRef.current = new mapboxgl.Popup({
                closeOnClick: false,
                closeButton: true,
                anchor: "bottom",
                className: "sensePlusPopup",
            })
                .setLngLat([popupInfo.long, popupInfo.lat])
                .setDOMContent(container)
                .addTo(mapRef.current);

            popupRef.current.on("close", () => setPopupInfo(null));
        }
    }, [popupInfo]);

    useEffect(() => {
        if (popupRef.current) {
            popupRef.current.remove();
        }
    }, [mapOption]);

    return (
        <div className="mapVis" style={{ height: "100%", width: "100%" }}>
            <div
                ref={mapContainerRef}
                style={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: "#141414",
                }}
            />
            <SensePlusMapLegend
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
                setFilterString={setFilterString}
                setStateFilter={setStateFilter}
                mapRef={mapRef}
            />
        </div>
    );
}

export default SensePlusMap;
