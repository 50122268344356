import React, { useState, useEffect } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import "./SensePlusMap.css";
import { Button, Input, AutoComplete } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

const { Search } = Input;

function SensePlusMapLegend({
    sidebarOpen,
    toggleSidebar,
    setFilterString,
    setStateFilter,
    mapRef,
}) {
    const [searchOptions, setSearchOptions] = useState([]);

    const initialOptions = [
        { value: "Acre" },
        { value: "Alagoas" },
        { value: "Amapá" },
        { value: "Amazonas" },
        { value: "Bahia" },
        { value: "Ceará" },
        { value: "Distrito Federal" },
        { value: "Espírito Santo" },
        { value: "Goiás" },
        { value: "Maranhão" },
        { value: "Mato Grosso" },
        { value: "Mato Grosso do Sul" },
        { value: "Minas Gerais" },
        { value: "Pará" },
        { value: "Paraíba" },
        { value: "Paraná" },
        { value: "Pernambuco" },
        { value: "Piauí" },
        { value: "Rio de Janeiro" },
        { value: "Rio Grande do Norte" },
        { value: "Rio Grande do Sul" },
        { value: "Rondônia" },
        { value: "Roraima" },
        { value: "Santa Catarina" },
        { value: "São Paulo" },
        { value: "Sergipe" },
        { value: "Tocantins" },
    ];

    const handleToggleSidebar = () => {
        toggleSidebar();
        setTimeout(() => {
            if (mapRef.current) {
                mapRef.current.resize();
            }
        }, 600);
    };

    const handleSearch = (value) => {
        if (!value) {
            setSearchOptions(initialOptions);
            return;
        }
        const filteredOptions = initialOptions.filter((option) =>
            option.value.toLowerCase().includes(value.toLowerCase())
        );
        setSearchOptions(filteredOptions);
    };

    useEffect(() => {
        setSearchOptions(initialOptions);
    }, []);

    return (
        <div className="sensePlusFilterContainer">
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <Button
                    className="sensePlusFilterButton"
                    onClick={handleToggleSidebar}
                    icon={
                        sidebarOpen ? (
                            <MenuFoldOutlined />
                        ) : (
                            <MenuUnfoldOutlined />
                        )
                    }
                />
                <AutoComplete
                    popupClassName="sensePlusSearchDropdown"
                    style={{ width: "100%" }}
                    options={searchOptions}
                    onSearch={handleSearch}
                    onSelect={(value) => setStateFilter(value)}
                >
                    <Search
                        className="sensePlusSearch"
                        placeholder="Pesquise aqui..."
                        size="large"
                        onSearch={(value) => setFilterString(value)}
                    />
                </AutoComplete>
            </div>
        </div>
    );
}

export default SensePlusMapLegend;
