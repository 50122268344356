import React, { useEffect, useState } from "react";
import { Spin, Switch, TreeSelect } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./sensePlusSideBar.css";

const { SHOW_PARENT } = TreeSelect;

const treeData = [
    {
        title: "Cultura",
        value: "crop_type",
        key: "crop_type",
        children: [
            { title: "Soja", value: "soja", key: "soja" },
            { title: "Milho", value: "milho", key: "milho" },
            { title: "Arroz", value: "arroz", key: "arroz" },
        ],
    },
    {
        title: "Estágio do plantio",
        value: "crop_stages",
        key: "crop_stages",
        children: [
            { title: "Plantados", value: "sowed", key: "sowed" },
            {
                title: "Próximo da colheita",
                value: "near_harvest",
                key: "near_harvest",
            },
            { title: "Colhido", value: "harvested", key: "harvested" },
            { title: "Não identificado", value: "others", key: "others" },
        ],
    },
];

const SensePlusSideBarHeader = ({
    tickets,
    hasMore,
    onFilterChange,
    onUserTicketsChange,
}) => {
    const [filteredAlertTickets, setFilteredAlertTickets] = useState([]);
    const [value, setValue] = useState([]);
    const [isSwitchOn, setIsSwitchOn] = useState(false);

    const onChange = (newValue) => {
        setValue(newValue);

        const filters = {};

        treeData.forEach((group) => {
            let selectedValues = [];

            if (newValue.includes(group.value)) {
                selectedValues = group.children.map((child) => child.value);
            } else {
                selectedValues = group.children
                    .filter((child) => newValue.includes(child.value))
                    .map((child) => child.value);
            }

            if (selectedValues.length > 0) {
                const key =
                    group.value === "crop_tipe" ? "crop_type" : group.value;
                filters[key] = selectedValues;
            }
        });

        if (typeof onFilterChange === "function") {
            onFilterChange(filters);
        }
    };

    useEffect(() => {
        const alertFiltered = tickets.filter(
            (ticket) => ticket.notification.notification_type
        );

        setFilteredAlertTickets(alertFiltered);
    }, [tickets]);

    return (
        <div className="sidebar-header">
            <div className="sidebar-header-title">Painel de Insights</div>
            <TreeSelect
                treeData={treeData}
                value={value}
                onChange={onChange}
                treeCheckable={true}
                showCheckedStrategy={SHOW_PARENT}
                placeholder="Filtre seus casos"
                style={{ width: "100%" }}
                className="sensePlusFilterSelect"
                popupClassName=""
            />

            <div className="sensePlusSidebarSwitchLine">
                <>Exibir apenas meus tickets</>
                <Switch
                    onChange={(checked) => {
                        setIsSwitchOn(checked);
                        onUserTicketsChange();
                    }}
                    style={{
                        backgroundColor: isSwitchOn ? "#9236D9" : "#ccc",
                    }}
                />
            </div>

            <div className="sensePlusSidebarCounter">
                <span style={{ color: "#1890FF" }}>Contratos Monitorados:</span>
                {tickets.length}
                {hasMore && (
                    <Spin
                        indicator={
                            <LoadingOutlined
                                style={{
                                    fontSize: 20,
                                    color: "#ffffffd9",
                                }}
                                spin
                            />
                        }
                    />
                )}
            </div>
            <div className="sensePlusSidebarCounter">
                <span style={{ color: "#FAAD14" }}>Contratos em Alerta:</span>
                {filteredAlertTickets.length}
            </div>
        </div>
    );
};

export default SensePlusSideBarHeader;
