import React, { useState, useEffect, useRef } from "react";
import SensePlusMap from "../components/sensePlus/sensePlusMap";
import {
    fetchSensePlusMapData,
    fetchSensePlusTickets,
} from "../components/api/api";
import "./SensePlus.css";
import PlantingProgress from "../components/sensePlus/PlantingChart";
import CropDistributionChart from "../components/sensePlus/CropDistributionChart";
import LoadingPage from "../components/dashboard/LoadingPage";
import SensePlusSideBarHeader from "../components/sensePlus/sensePlusSideBarHeader";
import BottomBar from "../components/sensePlus/sensePlusBottomBar";
import { Segmented } from "antd";
import {
    AlertOutlined,
    BarChartOutlined,
    CloudOutlined,
    DashboardOutlined,
    MonitorOutlined,
} from "@ant-design/icons";

const processAggregates = (aggregates) => {
    const cropCultures = Object.entries(aggregates.crop_cultures).map(
        ([key, value]) => ({
            name: key,
            area: value.area,
            percentage: value.percentage,
        })
    );

    const cropStages = Object.entries(aggregates.crop_stages).map(
        ([key, value]) => ({
            name: key,
            area: value.area,
            percentage: value.percentage,
        })
    );

    return { cropCultures, cropStages };
};

function SensePlus() {
    const [tickets, setTickets] = useState([]);
    const [mapTickets, setMapTickets] = useState([]);
    const [tableHasMore, setTableHasMore] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [mapboxToken, setMapboxToken] = useState("");
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [filterString, setFilterString] = useState("");
    const [stateFilter, setStateFilter] = useState("");
    const [userTicketFilter, setUserTicketFilter] = useState(false);
    const [treeselectFilter, setTreeselectFilter] = useState([]);
    const [bottomBarOpen, setBottomBarOpen] = useState(false);
    const [cropCultures, setCropCultures] = useState([]);
    const [cropStages, setCropStages] = useState([]);
    const [selectedMap, setSelectedMap] = useState("monitoring");

    const mapRef = useRef(null);

    const tableAbortControllerRef = useRef(null);
    const abortControllerRef = useRef(null);

    const STATE_UF = {
        Acre: "AC",
        Alagoas: "AL",
        Amapá: "AP",
        Amazonas: "AM",
        Bahia: "BA",
        Ceará: "CE",
        "Distrito Federal": "DF",
        "Espírito Santo": "ES",
        Goiás: "GO",
        Maranhão: "MA",
        "Mato Grosso": "MT",
        "Mato Grosso do Sul": "MS",
        "Minas Gerais": "MG",
        Pará: "PA",
        Paraíba: "PB",
        Paraná: "PR",
        Pernambuco: "PE",
        Piauí: "PI",
        "Rio de Janeiro": "RJ",
        "Rio Grande do Norte": "RN",
        "Rio Grande do Sul": "RS",
        Rondônia: "RO",
        Roraima: "RR",
        "Santa Catarina": "SC",
        "São Paulo": "SP",
        Sergipe: "SE",
        Tocantins: "TO",
    };

    const fetchAllTableTickets = async () => {
        try {
            if (tableAbortControllerRef.current) {
                tableAbortControllerRef.current.abort();
            }

            const controller = new AbortController();
            tableAbortControllerRef.current = controller;

            let page = 1;
            let response;

            setTickets([]);
            setTableHasMore(true);

            do {
                const fetchWithAbort = async () => {
                    const result = await Promise.race([
                        fetchSensePlusTickets(
                            page,
                            filterString,
                            STATE_UF[stateFilter],
                            treeselectFilter,
                            userTicketFilter
                        ),
                        new Promise((_, reject) =>
                            controller.signal.addEventListener("abort", () =>
                                reject(new DOMException("AbortError"))
                            )
                        ),
                    ]);
                    return result;
                };

                response = await fetchWithAbort();

                if (!mapboxToken) {
                    setMapboxToken(response.mapbox_token);
                    const { cropCultures: cultures, cropStages: stages } =
                        processAggregates(response.aggregates);
                    setCropCultures(cultures);
                    setCropStages(stages);
                }

                const filteredResults = response.results.map((ticket) => ({
                    id: ticket.id,
                    clientId: ticket.farmer_name,
                    proposalId: ticket.proposal_id,
                    area: ticket.location_data.hectares,
                    cropType: ticket.crop_type,
                    state: ticket.location_data.state,
                    sicarOverlap: ticket.sicar_overlaps,
                    long: ticket.location_data.long,
                    lat: ticket.location_data.lat,
                    municipality: ticket.location_data.municipality,
                    notification: ticket.atypical_events,
                }));
                setTickets((prevTickets) => [
                    ...prevTickets,
                    ...filteredResults,
                ]);

                if (!response.next) setTableHasMore(false);

                page++;
            } while (response.next);
        } catch (error) {
            if (error.name === "AbortError") {
                console.log("Requisição abortada devido à mudança no filtro.");
            } else {
                console.error("Erro ao carregar tickets:", error);
            }
        }
    };

    const fetchMapTickets = async () => {
        try {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }

            const controller = new AbortController();
            abortControllerRef.current = controller;

            let page = 1;
            let response;

            setMapTickets([]);
            setHasMore(true);

            do {
                const fetchWithAbort = async () => {
                    const result = await Promise.race([
                        fetchSensePlusMapData(
                            page,
                            filterString,
                            STATE_UF[stateFilter],
                            treeselectFilter,
                            userTicketFilter
                        ),
                        new Promise((_, reject) =>
                            controller.signal.addEventListener("abort", () =>
                                reject(new DOMException("AbortError"))
                            )
                        ),
                    ]);
                    return result;
                };

                response = await fetchWithAbort();

                const filteredResults = response.results.map((ticket) => ({
                    id: ticket.id,
                    long: ticket.coordinates.long,
                    lat: ticket.coordinates.lat,
                    monitoringColor: ticket.monitoring_color,
                    riskColor: ticket.climate_risk_color,
                }));
                setMapTickets((prevMapTickets) => [
                    ...prevMapTickets,
                    ...filteredResults,
                ]);

                if (!response.next) setHasMore(false);

                page++;
            } while (response.next);
        } catch (error) {
            if (error.name === "AbortError") {
                console.log("Requisição abortada devido à mudança no filtro.");
            } else {
                console.error("Erro ao carregar tickets:", error);
            }
        }
    };

    useEffect(() => {
        fetchAllTableTickets();
        fetchMapTickets();

        return () => {
            if (tableAbortControllerRef.current) {
                tableAbortControllerRef.current.abort();
            }
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, [filterString, stateFilter, treeselectFilter, userTicketFilter]);

    const toggleSidebar = () => {
        setSidebarOpen((prev) => !prev);
    };

    const toggleBottomBar = () => {
        setBottomBarOpen((prev) => !prev);
    };

    const handleSearchFilter = (value) => {
        setStateFilter("");
        setFilterString(value);
    };

    const handleStateFilterChange = (value) => {
        setStateFilter(value);
    };

    const handleSegmentedChange = (value) => {
        setSelectedMap(value);
    };

    const handletreeselectChange = (value) => {
        setTreeselectFilter(value);
    };

    const onUserTicketsChange = () => {
        setUserTicketFilter((prev) => !prev);
    };

    if (!mapboxToken) {
        return <LoadingPage customMessages={["Carregando"]} />;
    }

    return (
        <div style={{ backgroundColor: "#424242" }}>
            <Segmented
                className="sensePlusSegmented"
                size="large"
                onChange={handleSegmentedChange}
                options={[
                    {
                        label: "Monitoramento",
                        value: "monitoring",
                        icon: <MonitorOutlined />,
                    },
                    {
                        label: "Clima",
                        value: "climate",
                        icon: <CloudOutlined />,
                        disabled: true,
                    },
                    {
                        label: "Produtividade",
                        value: "productivity",
                        icon: <BarChartOutlined />,
                        disabled: true,
                    },
                    {
                        label: "Área de risco",
                        value: "riskZone",
                        icon: <AlertOutlined />,
                    },
                    {
                        label: "Dashboard",
                        value: "dashboard",
                        icon: <DashboardOutlined />,
                        disabled: true,
                    },
                ]}
                block
            />
            <div className={`sidebar ${sidebarOpen ? "open" : ""}`}>
                <SensePlusSideBarHeader
                    tickets={tickets}
                    hasMore={tableHasMore}
                    onFilterChange={handletreeselectChange}
                    onUserTicketsChange={onUserTicketsChange}
                />
                <div className="sidebar-body">
                    <PlantingProgress cropStages={cropStages} />
                    <CropDistributionChart cropCultures={cropCultures} />
                </div>
            </div>
            <div
                style={{
                    marginLeft: sidebarOpen ? "376px" : "0",
                    marginBottom: bottomBarOpen ? "250px" : "56px",
                    transition: "margin 0.5s",
                    height: "calc(100vh - 88px)",
                    overflow: "hidden",
                }}
            >
                <div
                    style={{
                        height: "100%",
                        width: "100%",
                        position: "relative",
                    }}
                >
                    {mapboxToken && (
                        <SensePlusMap
                            tickets={mapTickets}
                            tableTickets={tickets}
                            mapOption={selectedMap}
                            mapboxToken={mapboxToken}
                            sidebarOpen={sidebarOpen}
                            toggleSidebar={toggleSidebar}
                            setFilterString={handleSearchFilter}
                            setStateFilter={handleStateFilterChange}
                            mapRef={mapRef}
                        />
                    )}
                </div>
            </div>
            <BottomBar
                bottomBarOpen={bottomBarOpen}
                toggleBottomBar={toggleBottomBar}
                sidebarOpen={sidebarOpen}
                tickets={tickets}
                mapRef={mapRef}
                hasMore={tableHasMore}
            />
        </div>
    );
}

export default SensePlus;
