import { parseCookies } from "nookies";

const cookies = parseCookies();

const BASE_URL =
    process.env.REACT_APP_BASE_URL || "https://app.agromai.com.br/api/v1";

// Function to handle fetch requests with error handling
async function handleFetch(url, options = {}, timeout = 100000) {
    let headers = {};

    if (options.headers && options.headers.hasOwnProperty("Authorization")) {
        headers = {
            ...options.headers,
        };
    } else {
        const cookies = parseCookies();

        headers = {
            Authorization: `Token ${cookies["auth-token"]}`,
            ...options.headers,
        };
    }

    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), timeout);

    try {
        const response = await fetch(url, {
            headers: headers,
            signal: controller.signal,
            ...options,
        });

        // Check the response content type
        const contentType = response.headers.get("content-type");

        let responseData;
        if (contentType && contentType.includes("application/json")) {
            responseData = await response.json();
        } else {
            responseData = await response.blob();
        }

        if (!response.ok) {
            const error = new Error("HTTP error");
            error.code = response.status;
            error.responseData = responseData;
            throw error;
        }

        return responseData;
    } catch (error) {
        if (error.name === "AbortError") {
            const timeoutError = new Error("Request timed out");
            timeoutError.code = "TIMEOUT";
            throw timeoutError;
        }
        throw error;
    } finally {
        clearTimeout(timeoutId);
    }
}
// Function to validate KML file
// export async function validateKMLFile(file) {
//   const formData = new FormData();
//   formData.append("file", file);

//   return handleFetch(`${BASE_URL}/validate-kml/`, {
//     method: "POST",
//     body: formData,
//   });
// }

export const createTicketsAPI = async (formDataList) => {
    const url = `${BASE_URL}/create-tickets`;

    // Recupera o token de autenticação
    const cookies = parseCookies();
    const authToken = cookies["auth-token"]; // Ajuste se o nome do cookie for diferente

    if (!authToken) {
        throw new Error("Token de autenticação não encontrado");
    }

    const farms = formDataList.map((formData) => {
        let auraStatus = false;
        let senseStatus = false;
        let visionStatus = false;

        if (formData.serviceType === "aura") {
            auraStatus = true;
        } else if (formData.serviceType === "sense") {
            senseStatus = true;
            formData.cycleType = "1";
        } else if (formData.serviceType === "vision") {
            visionStatus = true;
            formData.cycleType = "1";
            formData.cropType = "laudo";
        }

        return {
            farm_name: formData.farmName || "Default Farm",
            farmer_name: formData.producerName || "Default Farmer",
            field_name: formData.fieldName,
            cycle_type: parseInt(formData.cycleType, 10),
            soil_type_: parseInt(formData.soilType, 10) || null,
            soil_type_ad: parseInt(formData.soilType, 10) || null,
            created_by: 1,
            proposal_id: formData.proposalId,
            crop_type: formData.cropType,
            ticket_parameters: {
                consultation_status: "P",
                aura_status: auraStatus,
                sense_status: senseStatus,
                vision_status: visionStatus,
            },
            geometry_data: {
                geom: formData.geometry,
                file_name: formData.fileName,
            },
        };
    });

    const farmData = {
        farm_data: {
            farmer: formDataList[0]?.producerName || "Default Farmer",
            ticket_data: farms,
        },
    };

    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${authToken}`,
            },
            body: JSON.stringify(farmData), // Corrige para enviar como JSON string
        });

        // Verifica a resposta da API
        if (!response.ok) {
            const errorResponse = await response.json();
            const error = new Error("Erro ao criar tickets");
            error.responseData = errorResponse;
            throw error;
        }

        return await response.json(); // Retorna a resposta em JSON
    } catch (error) {
        console.error("Erro ao criar tickets:", error);

        if (error.responseData) {
            console.error("Server Response:", error.responseData);
        }

        throw error;
    }
};

export const validateFilesAPI = async (files) => {
    const url = `${BASE_URL}/validate-ticket-files`;

    const formData = new FormData();
    files.forEach((file) => formData.append("documents", file));

    try {
        // Utilizando o helper handleFetch para fazer a requisição
        const response = await handleFetch(url, {
            method: "POST",
            body: formData,
        });

        return response;
    } catch (error) {
        console.error("Erro na validação de arquivos:", error);
        throw error;
    }
};

// Function to send kmls for processing + ticket generation
// export async function submitAnalysis(formData) {
//   const formDataToSend = new FormData();
//   formDataToSend.append("kml_document", formData.file);
//
//   // Determine consultation_status based on serviceType
//   let auraStatus;
//   let senseStatus;
//   if (formData.serviceType === "Aura") {
//     auraStatus = true;
//     senseStatus = false;
//   } else if (formData.serviceType === "Sense") {
//     auraStatus = false;
//     senseStatus = true;
//   }
//
//   const farmData = {
//     farmer: formData.producerName,
//     farms: [
//       {
//         farm_name: formData.farmName,
//         field_name: formData.fieldName,
//         cycle_type: parseInt(formData.cycleType),
//         soil_type: parseInt(formData.soilType),
//         created_by: cookies["user-id"],
//         proposal_id: formData.proposalId,
//         crop_type: formData.cropType,
//         ticket_parameters: {
//           consultation_status: "P",
//           aura_status: auraStatus,
//           sense_status: senseStatus,
//         },
//       },
//     ],
//   };
//
//   // Log the farmData to the console before appending it to formDataToSend
//   formDataToSend.append("farm_data", JSON.stringify(farmData));
//
//   return handleFetch(`${BASE_URL}/consultation-tickets/`, {
//     method: "POST",
//     body: formDataToSend,
//   });
// }

// Weather Data Endpoints
export async function fetchWeatherReports(KMLID) {
    return handleFetch(`${BASE_URL}/weather-reports/?ticket_id=${KMLID}`);
}

export async function fetchAuraInsights(KMLID) {
    return handleFetch(`${BASE_URL}/ticket-ai-insight?ticket_id=${KMLID}`);
}

export async function fetchMonthlyWeatherData(KMLID) {
    return handleFetch(
        `${BASE_URL}/weather-data/get_monthly_data?ticket_id=${KMLID}`
    );
}

export async function fetchRiskScore(KMLID) {
    return handleFetch(
        `${BASE_URL}/consultation-tickets/${KMLID}/get_risk_score`
    );
}

export async function updateTicketStatus(ticket, newStatus) {
    const cookies = parseCookies();

    if (ticket.hasOwnProperty("farm_data")) {
        return handleFetch(`${BASE_URL}/consultation-tickets/${ticket.id}/`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${cookies["auth-token"]}`,
            },
            body: JSON.stringify({
                id: ticket.id,
                farm_data: ticket.farm_data.id,
                consultation_status: newStatus,
                risk_score: ticket.risk_score,
                ticket_priority: ticket.ticket_priority,
                sense_status: ticket.sense_status,
                aura_status: ticket.aura_status,
            }),
        });
    } else {
        return handleFetch(`${BASE_URL}/consultation-tickets/${ticket.id}/`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${cookies["auth-token"]}`,
            },
            body: JSON.stringify({
                id: ticket.id,
                farm_data: Number(ticket.farm_id),
                consultation_status: newStatus,
                risk_score: ticket.risk_score,
                ticket_priority: ticket.ticket_priority,
                sense_status: ticket.sense_status,
                aura_status: ticket.aura_status,
            }),
        });
    }
}

export async function deleteUserTicket(KMLID) {
    return handleFetch(`${BASE_URL}/consultation-tickets/${KMLID}/`, {
        method: "DELETE",
    });
}

export async function deleteUserTicketFromListing(KMLID) {
    return handleFetch(`${BASE_URL}/monitoring-reports/${KMLID}/`, {
        method: "DELETE",
    });
}

export async function fetchTicketData(KMLID) {
    return handleFetch(`${BASE_URL}/consultation-tickets/${KMLID}`);
}

export async function fetchAuraTickets(
    page = 1,
    searchString = "",
    order_by = ""
) {
    let searchParameters = "";

    if (searchString) {
        searchParameters += `&search_string=${searchString}`;
    }

    if (order_by) {
        searchParameters += `&order_by=${order_by}`;
    }

    return handleFetch(
        `${BASE_URL}/consultation-tickets?page=${page}&aura_status=true${searchParameters}`
    );
}

export async function fetchSenseTickets(
    page = 1,
    searchString = "",
    order_by = ""
) {
    let searchParameters = "";

    if (searchString) {
        searchParameters += `&search_string=${searchString}`;
    }

    if (order_by) {
        searchParameters += `&order_by=${order_by}`;
    }

    return handleFetch(
        `${BASE_URL}/consultation-tickets?page=${page}&sense_status=true${searchParameters}`
    );
}

export async function fetchVisionTickets(
    page = 1,
    searchString = "",
    order_by = ""
) {
    let searchParameters = "";

    if (searchString) {
        searchParameters += `&search_string=${searchString}`;
    }

    if (order_by) {
        searchParameters += `&order_by=${order_by}`;
    }

    return handleFetch(
        `${BASE_URL}/consultation-tickets?page=${page}&vision_status=true${searchParameters}`
    );
}

export async function fetchSensePlusTickets(
    page = 1,
    filterString = "",
    stateFilter = "",
    treeselectFilter = {},
    userTickets = false
) {
    let searchParameters = "";

    if (filterString) {
        searchParameters = `&search_string=${filterString}`;
    }

    if (stateFilter) {
        searchParameters = `&state=${stateFilter}`;
    }

    if (userTickets) {
        searchParameters = `&own_tickets=1`;
    }

    if (treeselectFilter && Object.keys(treeselectFilter).length > 0) {
        Object.entries(treeselectFilter).forEach(([key, values]) => {
            if (values && values.length > 0) {
                searchParameters += `&${key}=${values.join(",")}`;
            }
        });
    }

    return handleFetch(
        `${BASE_URL}/sense-plus?page=${page}${searchParameters}`
    );
}

export async function fetchSensePlusMapData(
    page = 1,
    filterString = "",
    stateFilter = "",
    treeselectFilter = {},
    userTickets = false
) {
    let searchParameters = "";

    if (filterString) {
        searchParameters = `&search_string=${filterString}`;
    }

    if (stateFilter) {
        searchParameters = `&state=${stateFilter}`;
    }

    if (userTickets) {
        searchParameters = `&own_tickets=1`;
    }

    if (treeselectFilter && Object.keys(treeselectFilter).length > 0) {
        Object.entries(treeselectFilter).forEach(([key, values]) => {
            if (values && values.length > 0) {
                searchParameters += `&${key}=${values.join(",")}`;
            }
        });
    }

    return handleFetch(
        `${BASE_URL}/sense-plus/report-tabs?page=${page}${searchParameters}`
    );
}

export async function fetchVisionDashboardData(state, lon, lat, app) {
    return handleFetch(
        `${BASE_URL}/dashboard?state=${state}&lon=${lon}&lat=${lat}&app=${app}`
    );
}

export async function fetchTrueImageDownload(ticket_id) {
    const url = `${BASE_URL}/download-report-multiband/${ticket_id}`;
    const cookies = parseCookies();
    const options = {
        method: "GET",
        headers: {
            Authorization: `Token ${cookies["auth-token"]}`,
        },
    };
    const response = await fetch(url, options);
    if (!response.ok) {
        const error = new Error("HTTP error");
        error.code = response.status;
        error.responseData = await response.json();
        throw error;
    }
    // Read the Blob from the response
    const blob = await response.blob();
    // Return both the Blob and the headers
    return {
        blob,
        headers: response.headers,
    };
}

export async function fetchSenseTicketData(KMLID) {
    return handleFetch(
        `${BASE_URL}/monitoring-reports/latest-report/?ticket_id=${KMLID}`
    );
}

export async function fetchSenseDatedReports(KMLID) {
    return handleFetch(`${BASE_URL}/monitoring-reports?ticket_id=${KMLID}`);
}

// Visualization Endpoints
export async function fetchMapContext(report_type, ticket_id) {
    const requestData = {
        ticket_id,
        report_type,
        start_date: "2023-10-09",
    };

    const cookies = parseCookies();
    return handleFetch(`${BASE_URL}/map-reports/generate-map-context/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${cookies["auth-token"]}`,
        },
        body: JSON.stringify(requestData),
    });
}

export async function fetchNDVI(
    ticket_id,
    report_type,
    years,
    report_periodicity,
    weather_periodicity
) {
    const requestData = {
        ticket_id,
        report_type,
        years,
        report_periodicity,
        weather_periodicity,
    };
    const cookies = parseCookies();
    return await handleFetch(`${BASE_URL}/data-reports/collective_data/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${cookies["auth-token"]}`,
        },
        body: JSON.stringify(requestData),
    });
}

// Map Endpoints
export async function fetchMap(KMLID) {
    return handleFetch(
        `${BASE_URL}/consultation-tickets/${KMLID}/get_map_html`
    );
}

export async function loadNotionContent(pageId) {
    const response = await fetch(
        `https://notion-api.splitbee.io/v1/page/${pageId}`
    );
    const data = await response.json();
    return data;
}

// Vision endpoints
export async function fetchVisionData(KMLID) {
    return handleFetch(`${BASE_URL}/vision/${KMLID}`);
}

// Vision endpoints
export async function fetchVisionReport(KMLID) {
    const response = await fetch(
        `${BASE_URL}/vision/${KMLID}/download-report`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/pdf",
            },
        }
    );

    if (!response.ok) {
        throw new Error(`Error fetching vision report: ${response.statusText}`);
    }

    const blob = await response.blob();

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `vision_report_${KMLID}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    window.URL.revokeObjectURL(url);
}

// Billing endpoints
export async function fetchBillingReport() {
    return handleFetch(`${BASE_URL}/billing-report`);
}

export async function fetchBillingReportHistory(page = 1) {
    return handleFetch(`${BASE_URL}/billing-ticket-history?page=${page}`);
}

export async function fetchDataUser(url, data, method, options = {}) {
    const cookies = parseCookies();
    const controller = new AbortController();

    try {
        const response = await fetch(url, {
            method: method,
            body: data ? JSON.stringify(data) : null,
            headers: {
                ...options.headers,
                Authorization: `Token ${cookies["auth-token"]}`,
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            signal: controller.signal,
            ...options,
        });
        const text = await response.text();
        return text ? JSON.parse(text) : {};
    } catch (error) {
        console.error(`Erro ao enviar requisição POST para ${url}:`, error);
        throw error;
    }
}

export async function fetchUsers(page = 1) {
    return handleFetch(`${BASE_URL}/users?page=${page}`);
}

export async function createUser(userData) {
    try {
        const response = await fetchDataUser(
            `${BASE_URL}/users/`,
            userData,
            "POST"
        );
        return response;
    } catch (error) {
        console.error("Erro ao criar usuário:", error);
        throw error;
    }
}

export async function updateUser(userData) {
    try {
        const { id, ...data } = userData;

        const response = await fetchDataUser(
            `${BASE_URL}/users/${id}/`,
            data,
            "PUT"
        );
        return response;
    } catch (error) {
        console.error("Erro ao criar usuário:", error);
        throw error;
    }
}

export async function deleteUser(id) {
    try {
        const response = await fetchDataUser(
            `${BASE_URL}/users/${id}/delete`,
            null,
            "DELETE"
        );
        return response;
    } catch (error) {
        console.error("Erro ao criar usuário:", error);
        throw error;
    }
}
