import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  fetchTicketData,
  fetchNDVI,
  fetchWeatherReports,
  fetchAuraInsights,
} from "../../../components/api/api";
import generateTemperatureData from "../../../components/dashboard/functions/GenerateTemperatureData";

import "../Aura.css";
import AuraHeader from "../AuraHeader";
import LoadingPage from "../../../components/dashboard/LoadingPage";
import { Tabs } from "antd";
import ESGCardsListAura from "../../../components/dashboard/ESGCardsListAura";
import AuraHistoricalParameters from "../AuraHistoricalParameters";
import RainfallChart from "../../../components/dashboard/RainfallChart";
import AuraInsights from "../AuraInsights";
import ClimaticRisk from "../../../components/dashboard/ClimaticRisk";
import AuraFieldAnalysis from "../AuraFieldAnalysis";
import AuraMap from "../../../components/dashboard/AuraMap";
import AuraClimateAnalysis from "../AuraClimateAnalysis";
import AuraAnalysisInsights from "../AuraAnalysisInsights";
import { Responsive, WidthProvider } from 'react-grid-layout';
const ResponsiveGridLayout = WidthProvider(Responsive);

function NewAuraReport1() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [insights, setInsights] = useState("");
  const [insights3, setInsights3] = useState("");
  const [loadingInsights, setLoadingInsights] = useState(false);
  const [weatherReports, setWeatherReports] = useState(null);    
  const [status, setRiskCalculationStatus] = useState(null);
  const [coords, setCoords] = useState(null);
  const [currentLayout, setCurrentLayout] = useState('lg');
  const [culture, setCulture] = useState("");
  const [activeTab, setActiveTab] = useState("1");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ticketData = await fetchTicketData(id);
        setRiskCalculationStatus(ticketData.risk_calculation_status);
        setCoords([ticketData.center_coords.lat, ticketData.center_coords.long]);
        setInsights(ticketData?.ai_insights?.ai_analysis);
        if (ticketData?.ai_insights?.ai_analysis) {
          const topics = ticketData.ai_insights.ai_analysis.split(/\n\n(?=\d+\.\s)/);
          setInsights3(topics[2]);
        }
        if (ticketData?.risk_classification_records.length >= 5) {
          const largestDateObject = ticketData?.risk_classification_records.filter(
            item => item.sowing_date.includes('2023') || item.sowing_date.includes('2024')).reduce(
              (max, item) => max.sowing_date > item.sowing_date ? max : item, { sowing_date: '' });

          setCulture(largestDateObject.culture);
        }
        setData(ticketData);
      } catch (error) {
        console.error("Error loading ticket data:", error);
      }

      try {
        const weatherData = await fetchWeatherReports(id);
        setWeatherReports(weatherData);
      } catch (error) {
        console.error("Error loading NDVI data:", error);
      }

      setIsLoading(false);
    };

    if (id) {
      if (data == null) {
        fetchData();
      } else if (status === 'calculating' || status == null) {
        const timeout = setTimeout(() => {
          fetchData();
        }, 20000);

        return () => clearTimeout(timeout);
      }
    }
  }, [id, data]);

  useEffect(() => {
    setIsLoading(true);
  }, [id]);

  useEffect(() => {
    setData(data);
  }, [data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) {
    return <LoadingPage />;
  }

  const handleInsights = async () => {
    setLoadingInsights(true);
    const insightsReponse = await fetchAuraInsights(id);

    if (insightsReponse) {
      setInsights(insightsReponse?.ai_insight?.ai_analysis);
      if (insightsReponse?.ai_insight?.ai_analysis) {
        const topics = insightsReponse.ai_insight.ai_analysis.split(/(?=\d+\.\s)/);
        setInsights3(topics[2]);
      }
    }
    setLoadingInsights(false);
  };

  const layouts = {
    lg: [
      { i: 'insights', x: 0, y: 0, w: 3, h: 5.85, static: true },
      { i: 'historicalParams', x: 3, y: 0, w: 4.8, h: 9.25, static: true },
      { i: 'fieldAnalysis', x: 0, y: 10, w: 3, h: 3.4, static: true },
      { i: 'esgCards', x: 7.9, y: 0, w: 2.2, h: 4, static: true },
      { i: 'climaticRisk', x: 0, y: 15, w: 3, h: 4, static: true },
      { i: 'rainfallChart', x: 4, y: 15, w: 7, h: 4, static: true },
    ],
    md: [
      { i: 'insights', x: 0, y: 0, w: 2, h: 8 },
      { i: 'fieldAnalysis', x: 0, y: 1, w: 1, h: 4 },
      { i: 'historicalParams', x: 3, y: 0, w: 2, h: 4 },
      { i: 'esgCards', x: 0, y: 1, w: 5, h: 4 },
      { i: 'climaticRisk', x: 2, y: 1, w: 5, h: 4 },
      { i: 'rainfallChart', x: 0, y: 2, w: 10, h: 4 },
    ],
    sm: [
      { i: 'insights', x: 0, y: 1, w: 6, h: 4 },
      { i: 'fieldAnalysis', x: 0, y: 2, w: 6, h: 4 },
      { i: 'historicalParams', x: 0, y: 3, w: 3, h: 4 },
      { i: 'esgCards', x: 0, y: 4, w: 6, h: 4 },
      { i: 'climaticRisk', x: 0, y: 5, w: 6, h: 4 },
      { i: 'rainfallChart', x: 0, y: 6, w: 6, h: 4 },
    ],
  };

  const handleBreakpointChange = (newBreakpoint) => {
    setCurrentLayout(newBreakpoint);
  };

  const renderRiskAnalysisTab = () => (
    <div className="reportContainer">
      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        breakpoints={{ lg: 1920, md: 1560, sm: 768 }}
        cols={{ lg: 12, md: 10, sm: 6 }}
        rowHeight={55}
        width={1920}
        onBreakpointChange={handleBreakpointChange}
      >
        <div key="insights" data-grid={{ x: 0, y: 0, w: 3, h: 5.81, static: true }}>
          <AuraInsights
            insights={insights}
            onGenerateInsight={handleInsights}
            loadingInsights={loadingInsights}
            crop={culture}
          />
        </div>
        <div key="fieldAnalysis" data-grid={{ x: 0, y: 5.82, w: 3, h: 3.3, static: true }}>
          <AuraFieldAnalysis data={weatherReports} />
        </div>

        <div key="historicalParams" data-grid={{ x: 3, y: 0, w: 4.8, h: 9.25, static: true }}>
          <AuraHistoricalParameters
            className="auraHistricalParams"
            data={data?.risk_classification_records}
            isCalculating={data?.risk_classification_records?.length < data?.total_classifications}
          />
        </div>
        <div key="esgCards" data-grid={{ x: 7.9, y: 0, w: 2.2, h: 4, static: true }}>
          <ESGCardsListAura sicar_overlaps={data?.sicar_overlaps} />
        </div>
        <div key="climaticRisk" data-grid={{ x: 0, y: 9.3, w: 3, h: 4, static: true }}>
          <ClimaticRisk className="AuraClimaticRisk" data={weatherReports} />
        </div>
        <div key="rainfallChart" data-grid={{ x: 4, y: 9.3, w: 7, h: 4, static: true }}>
          <RainfallChart
            className="AuraRainFall"
            data={weatherReports?.rainfall_data?.rainfall_sum ?? []}
          />
        </div>
      </ResponsiveGridLayout>
    </div>
  );

  const renderSoilAnalysisTab = (isVisible) => (
    <div className="elementFlexHorizontalAura">
      <div className="elementFlexVerticalAura" style={{ width: "23%" }}>
        <AuraClimateAnalysis
          data={weatherReports}
          crop={culture}
        />
        <AuraAnalysisInsights
          insights={insights3}
          onGenerateInsight={handleInsights}
          loadingInsights={loadingInsights}
          weatherReports={weatherReports}
          crop={culture}
        />
      </div>
      <div className="elementFlexVerticalAura" style={{ width: "77%" }}>
        <AuraMap
          id={data?.id}
          coords={coords}
          graphData={weatherReports?.organic_matter_graph_data}
          ndviGraphData={weatherReports?.curve_insights}
          classificationRecords={data?.risk_classification_records}
          isVisible={isVisible}
        />
      </div>
    </div>
  );

  return (
    <>
      <AuraHeader ticket={data} />
      <Tabs
        defaultActiveKey="1"
        tabBarGutter={32}
        onChange={(key) => setActiveTab(key)}
        items={[
        {
          key: "1",
          label: <span className="auraTabText">Análise de risco - Safra Verão</span>,
          children: renderRiskAnalysisTab(),
        },
        {
          key: "2",
          label: <span className="auraTabText">Análise do solo</span>,
          children: renderSoilAnalysisTab(activeTab === "2"),
        }
      ]} />
    </>
  );
};

export default NewAuraReport1;
